export const presentationPath = "/citius/presentation";
export const scienceSocietyPath = "/citius/science-society";
export const ourSpacesPath = "/citius/our-spaces";
export const organizationPath = "/citius/organization";
export const transparencyPath = "/citius/transparency";
export const contactPath = "/citius/contact";

export const researchersPath = "/team/researchers-senior";
export const researchersCollaboratorsPath = "/team/researchers-collaborators";
export const researchersPostdocPath = "/team/researchers-postdoc";
export const researchersPredocPath = "/team/researchers-predoc";
export const researchersStayPath = "/team/researchers-stay";
export const otherStartingPath = "/team/otherstaff-starting";
export const otherCollabFelloshipPath = "/team/otherstaff-collab-fellowship";
export const otherSummerFelloshipPath = "/team/otherstaff-summer-fellowship";
export const otherOtherPath = "/team/otherstaff-other";
export const managementPath = "/team/management";
export const alumniPath = "/team/alumni";
export const directoryPath = "/team/directory";
export const teamPath = "/team";

export const researchAreasPath = "/research/areas";
export const projectsPath = "/research/projects";
export const projectsArchivePath = "/research/projects-archive";
export const publicationsPath = "/research/publications";
export const articlesPath = "/research/publications/articles";
export const congressesPath = "/research/publications/congresses";
export const thesesPath = "/research/publications/theses";
export const booksPath = "/research/publications/books";
export const datasetsPath = "https://citius.usc.es/investigacion/datasets";

export const softwarePath = "https://citius.usc.es/transferencia/software";
export const spinOffsPath = "/transfer/spin-offs";

export const joinUsPath = "/talent/join-us";
export const joinUsDegreePath = "/talent/join-us/degree";
export const joinUsPredocPath = "/talent/join-us/predoc";
export const joinUsPostdocPath = "/talent/join-us/postdoc";

export const liveCiTIUSPath = "/talent/live-citius";
export const hrs4rPath = "/talent/hrs4r";
export const equalityPath = "/talent/equality";
export const trainingProgramPath = "/talent/training-program";
export const trainingProgramTransversalCompetencesPath =
  "/talent/training-program/transversal-competences";
export const trainingProgramDoctoralMeetingPath =
  "/talent/training-program/doctoral-meeting";
export const trainingProgramEmprendeLabPath =
  "/talent/training-program/emprendelab";
export const trainingProgramTechnologyPath =
  "/talent/training-program/technology";
export const trainingProgramTechnologyTransferPath =
  "/talent/training-program/technology-transfer";
export const masterDoctorsDegreesPath = "/talent/doctoral-master";
export const trainingCommissionPath = "/talent/training-commission";
export const jobOffersPath = "/talent/job-offers";
export const jobOffersArchivePath = "/talent/job-offers/archive";
export const expressionsOfInterestPath = "/talent/job-offers/expressions-of-interest";
export const expressionsOfInterestArchivePath = "/talent/job-offers/expressions-of-interest/archive";

export const newsRootPath = "/news";
export const allNewsArchivePath = "/news/archive/";
export const newsPath = "/news/news";
export const newsArchivePath = "/news/news/archive";
export const inTheMediaPath = "/news/in-the-media";
export const inTheMediaArchivePath = "/news/in-the-media/archive";

export const eventsRootPath = "/events";
export const nextEventsPath = "/events/upcoming-events";
export const eventsArchivePath = "/events/upcoming-events/archive";
export const insideTheLabPath = "/events/inside-the-lab";
export const lecturesPath = "/events/lectures";
export const lecturesKeynoteSpeechesPath =
  "/events/lectures/keynote-speeches";
export const lecturesDistinguishedResearchersPath =
  "/events/lectures/distinguished-researchers";
export const lecturesOtherCentersPath = "/events/lectures/other-centers";
export const conferencesPath = "/events/conferences";

export const videoPath = "/videos";
export const videoArchivePath = "/videos/archive";

export const searchEverythingPath = "/search/";
export const searchNewsEventsPath = "/search/news-events/";
export const searchPeoplePath = "/search/people/";
export const searchProjectsPath = "/search/projects/";
export const searchPublicationsPath = "/search/publications/";

export const navOptions = [
  {
    name: "home",
    link: "/",
  },
  {
    name: "CiTIUS",
    submenu: [
      {
        name: "presentation",
        link: presentationPath,
      },
      {
        name: "science&Society",
        link: scienceSocietyPath,
      },
      {
        name: "ourSpaces",
        link: ourSpacesPath,
      },
      {
        name: "organization",
        link: organizationPath,
      },
      {
        name: "transparency",
        link: transparencyPath,
      },
      {
        name: "contact",
        link: contactPath,
      },
    ],
  },
  {
    name: "team",
    submenu: [
      {
        name: "researchers",
        link: researchersPath,
      },
      {
        name: "management",
        link: managementPath,
      },
      {
        name: "otherStaff",
        link: otherOtherPath,
      },
      {
        name: "alumni",
        link: alumniPath,
      },
      {
        name: "directory",
        link: directoryPath,
      },
    ],
  },
  {
    name: "research",
    submenu: [
      {
        name: "researchAreas",
        link: researchAreasPath,
      },
      {
        name: "projects",
        link: projectsPath,
      },
      {
        name: "publications",
        link: publicationsPath,
      },
      {
        name: "theses",
        link: thesesPath,
      },
      {
        name: "datasets",
        link: datasetsPath,
      },
    ],
  },
  {
    name: "transfer",
    submenu: [
      {
        name: "software",
        link: softwarePath,
      },
      {
        name: "spinOffs",
        link: spinOffsPath,
      },
    ],
  },
  {
    name: "talent",
    submenu: [
      {
        name: "joinUs",
        link: joinUsPath,
      },
      {
        name: "jobOffers",
        link: jobOffersPath,
      },
      {
        name: "liveCiTIUS",
        link: liveCiTIUSPath,
      },
      {
        name: "HRS4R",
        link: hrs4rPath,
      },
      {
        name: "equality",
        link: equalityPath,
      },
      {
        name: "trainingProgram",
        link: trainingProgramPath,
      },
      {
        name: "master&DoctorsDegrees",
        link: masterDoctorsDegreesPath,
      },
      {
        name: "trainingCommission",
        link: trainingCommissionPath,
      },
    ],
  },
  {
    name: "newsSection",
    submenu: [
      {
        name: "news",
        link: newsPath,
      },
      {
        name: "inTheMedia",
        link: inTheMediaPath,
      },
    ],
  },
  {
    name: "events",
    submenu: [
      {
        name: "nextEvents",
        link: nextEventsPath,
      },
      {
        name: "insideTheLab",
        link: insideTheLabPath,
      },
      {
        name: "lectures",
        link: lecturesPath,
      },
      {
        name: "conferences",
        link: conferencesPath,
      },
    ],
  },
  {
    name: "video",
    link: videoPath,
  },
];
