export const modifyUrlWithLanguage = (url, language, defaultLanguage) => {
    if (language === defaultLanguage) {
      return url;
    }
  
    // If URL contains domain citius.usc.es, return url as-is
    if (url.includes("citius.usc.es")) {
      return url;
    }
  
    try {
      // Check if the URL is absolute
      const urlObj = new URL(url);
      const pathSegments = urlObj.pathname.split('/').filter(Boolean);
  
      // Insert the language code after the domain
      pathSegments.unshift(language);
  
      urlObj.pathname = '/' + pathSegments.join('/');
      return urlObj.toString();
    } catch (error) {
      // Handle relative URLs
      const pathSegments = url.split('/').filter(Boolean);
  
      // Insert the language code at the beginning
      pathSegments.unshift(language);
  
      return '/' + pathSegments.join('/');
    }
};