import * as React from "react";
import * as style from "./footer.module.scss";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import Link from "components/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import GithubIcon from "@mui/icons-material/GitHub";
import BlueSkyIcon from "components/Icons/BlueSkyIcon/BlueSkyIcon";
import logoCitius from "images/corporative/logo_citius_tagline_vertical_white.svg";
import uscLogo from "images/footer/usc.svg";
import conselleriaLogo from "images/footer/conselleria.svg";
import cigusLogoEs from "images/footer/cigus-es.svg";
import cigusLogoEn from "images/footer/cigus-en.svg";
import cigusLogoGl from "images/footer/cigus-gl.svg";
import cofundedEuLogoEs from "images/footer/cofunded-es.svg";
import cofundedEuLogoEn from "images/footer/cofunded-en.svg";
import euFundsLogo from "images/footer/eufunds.svg";
import oportuniusLogo from "images/footer/oportunius.svg";

const Footer = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  
  let cigusLogo = cigusLogoEs;
  let cofundedEuLogo = cofundedEuLogoEs;
  if(language === "en") {
    cigusLogo = cigusLogoEn;
    cofundedEuLogo = cofundedEuLogoEn;
  }
  else if(language === "gl") {
    cigusLogo = cigusLogoGl;
  }

  return (
    <footer className={style.Footer}>
      <div className={style.Footer_main}>
        <img src={logoCitius} className={style.Footer_logo} alt="" />
        <p className={style.Footer_address}>
          Rúa de Jenaro de la Fuente Domínguez,
          <br /> 15782 - Santiago de Compostela.
          <br />
          Telf. <Link to="tel:+34881816400">+34 881 816 400</Link> · Mail:{" "}
          <Link to="mailto:citius@usc.es">citius@usc.es</Link>
        </p>
        <div className={style.Footer_followUs}>
          <span>{t("footer.followUs")}</span>
          <Link to='https://bsky.app/profile/citius.gal'>
            <BlueSkyIcon />
          </Link>
          <Link to="https://www.linkedin.com/company/citiususc">
            <LinkedInIcon />
          </Link>
          <Link to="https://www.facebook.com/citiususc">
            <FacebookIcon />
          </Link>
          <Link to="https://youtube.com/@citiususc">
            <YoutubeIcon />
          </Link>
          <Link to="https://www.instagram.com/citiususc">
            <InstagramIcon />
          </Link>
          <Link to="https://www.github.com/citiususc">
            <GithubIcon />
          </Link>
        </div>
        <div className={style.Footer_quickLinks}>
          <div className={style.Footer_quickLinksCol}>
            <span className={style.Footer_quickLinksColTitle}>
              {t("footer.linksCol1.title")}
            </span>
            <Link to="/citius/contact">{t("footer.links.contact")}</Link>
            <Link to="/team/directory">{t("footer.links.team")}</Link>
            <Link to="https://hub.citius.gal/">
              {t("footer.links.intranet")}
            </Link>
          </div>
          <div className={style.Footer_quickLinksCol}>
            <span className={style.Footer_quickLinksColTitle}>
              {t("footer.linksCol2.title")}
            </span>
            <Link to="/videos">{t("footer.links.video")}</Link>
            <Link to="/research/publications">
              {t("footer.links.publications")}
            </Link>
            <Link to="/research/areas">{t("footer.links.researchAreas")}</Link>
          </div>
        </div>
        <p
          className={style.Footer_copyright}
        >{`© ${new Date().getFullYear()} ${t("footer.copywright")}`}</p>
        <div className={style.Footer_otherLinks}>
          <Link to="/privacy-policy">{t("footer.privacyPolicy")}</Link>
          <Link to="/sitemap">{t("footer.sitemap")}</Link>
          <Link to="/research/projects/centro-singular-de-investigacion-en-tecnoloxias-intelixentes/">
            {t("footer.feder")}
          </Link>
        </div>
      </div>
      <div className={style.Footer_institutional}>
        <div className={style.Footer_logos}>
          <div className={style.conselleria}>
            <img src={conselleriaLogo} alt="Xunta de Galicia - Consellería de Educación, Ciencia, Universidades e Formación Profesional" />
          </div>
          <div className={style.cigus}>
            <img src={cigusLogo} alt="CIGUS - Centros de Investigación del Sistema Universitario de Galicia" />
          </div>
          <div className={style.usc}>
            <img src={uscLogo} alt="Universidade de Santiago de Compostela - USC" />
          </div>
          <div className={style.oportunius}>
            <img src={oportuniusLogo} alt="Centro Oportunius" />
          </div>
          <div className={style.cofunded}>
            <img src={cofundedEuLogo} alt="Cofunded by European Union" />
          </div>
          <div className={style.eufunds}>
            <img src={euFundsLogo} alt="European Funds" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
