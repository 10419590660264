import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BlueSkyIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="m 6.5435686,4.7442533 c 2.1670159,1.6151104 4.5078024,4.9066646 5.3664304,6.6648857 0.858629,-1.7582211 3.189194,-5.0497753 5.366431,-6.6648857 1.563932,-1.1653329 4.098931,-2.0751103 4.098931,0.8075552 0,0.5724442 -0.327096,4.8248865 -0.52131,5.5199975 -0.674637,2.402221 -3.138084,3.015555 -5.325544,2.637332 3.833165,0.644 4.804233,2.790666 2.698548,4.937332 -3.996713,4.068443 -5.744636,-1.022222 -6.194394,-2.330666 -0.08177,-0.235111 -0.122662,-0.347555 -0.122662,-0.255555 0,-0.092 -0.04089,0.02044 -0.122661,0.255555 C 11.33758,17.624248 9.589657,22.714913 5.5929437,18.64647 3.4872585,16.489582 4.4685487,14.353138 8.2914918,13.698916 6.1040324,14.066916 3.6405851,13.463805 2.9659481,11.061584 2.7717344,10.366473 2.4446377,6.1140305 2.4446377,5.5415863 c 0,-2.8724433 2.5349997,-1.9728881 4.0989309,-0.8075552 z" />
        </SvgIcon>
    );
};

export default BlueSkyIcon;