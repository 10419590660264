export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    const userLanguage = localStorage.getItem('gatsby-i18next-language') || 'en';
    const currentPath = window.location.pathname;
    const currentDomain = window.location.hostname;

    // Extract the first segment of the path to check for an existing language prefix
    const pathSegments = currentPath.split('/');
    let currentPrefix = pathSegments[1]; // First path segment after '/'

    const supportedLanguages = ['en', 'es', 'gl'];
    currentPrefix = supportedLanguages.includes(currentPrefix) ? currentPrefix : '';

    // Redirect if no prefix is present and preferred language is not 'en'
    if (currentPrefix === '' && userLanguage !== 'en') {
      const newPath = `/${userLanguage}${currentPath}`.replace(/\/{2,}/g, '/');
      console.log(`Redirecting because need to add prefix, to: ${newPath}`);
      if (window.location.href !== "https://" + currentDomain + newPath){
        window.location.href = "https://" + currentDomain + newPath;
        return;
      }
    }

    // Replace existing language prefix if it is different from the preferred language
    if (currentPrefix !== userLanguage && !(currentPrefix === '' && userLanguage === 'en')) {
      // Reconstruct path without the prefix (if prefix includes language)
      const pathWithoutPrefix = currentPrefix === '' ? pathSegments.join('/') : pathSegments.slice(2).join('/'); 
      const newPath = (userLanguage === 'en') ? `/${pathWithoutPrefix}`.replace(/\/{2,}/g, '/') : `/${userLanguage}/${pathWithoutPrefix}`.replace(/\/{2,}/g, '/');
      console.log(`Redirecting because prefix is invalid, to: ${newPath}`);
      if(window.location.href !== "https://" + currentDomain + newPath){
        window.location.href = "https://" + currentDomain + newPath;
        return;
      }
    }

  }
};